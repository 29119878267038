import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto mt-12 mb-1 px-4" }
const _hoisted_2 = { class: "my-4 text-h6 font-weight-regular text-center" }
const _hoisted_3 = { class: "text-red-darken-1 mb-0 px-4 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app_bar, {
      color: "indigo-darken-1",
      density: "compact",
      theme: "dark"
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_form, {
          onSubmit: _withModifiers(_ctx.login, ["prevent"]),
          ref: "formRefs"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              justify: "center",
              "align-content": "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  class: "mt-4",
                  cols: "12",
                  sm: "10",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('login.message.main')), 1),
                      _createVNode(_component_v_text_field, {
                        variant: "outlined",
                        label: _ctx.$t('login.label.loginId'),
                        type: "text",
                        modelValue: _ctx.loginId,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginId) = $event)),
                        color: "indigo-darken-1",
                        rules: _ctx.requiredValidation()
                      }, null, 8, ["label", "modelValue", "rules"]),
                      _createVNode(_component_v_text_field, {
                        variant: "outlined",
                        label: _ctx.$t('login.label.password'),
                        type: "password",
                        modelValue: _ctx.password,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                        color: "indigo-darken-1",
                        rules: _ctx.requiredValidation()
                      }, null, 8, ["label", "modelValue", "rules"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.hasError)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 0,
                  justify: "center",
                  "align-content": "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "8",
                      md: "6",
                      class: "py-0"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_row, { justify: "center" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "11",
                  sm: "4",
                  md: "3",
                  class: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      type: "submit",
                      block: "",
                      size: "x-large",
                      theme: "dark",
                      color: "indigo-darken-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('login.action.login')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    })
  ]))
}