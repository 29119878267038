<template>
    <v-dialog v-model="dialogmodel" width="500" persistent>
      <v-card>
  
        <v-card-text
          class="pa-4 pre-line grey--text text--darken-4 text-subtitle-1 px-5"
        >
          <p class="mb-4">
            {{ $t('common.message.zeroData') }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="indigo darken-1"
            dark
            @click="ok"
          >
          {{ $t('common.action.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import Vue from 'vue';
  import api from "@/apis/staff";
  import router from "@/router/index";
  
  export default {
    props: {
      handleOk: {
        type: Function,
        required: true, 
      },
    },
    data() {
      return {
        dialogmodel: true,
      };
    },
    methods: {
      ok() {
        this.handleOk();
      },
    },
  };
  </script>
  <style>
  .pre-line {
    white-space: pre-line;
  }
  </style>
  