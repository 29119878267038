<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="12">
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                {{ $t('zaikoEpcList.label.janCode') }}{{ productInfo.janCode }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                {{ $t('zaikoEpcList.label.productName') }}{{ productInfo.productName }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                {{ $t('zaikoEpcList.label.productCode') }}{{ productInfo.productCode }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                {{ $t('zaikoEpcList.label.colorCode') }}{{ productInfo.colorCode }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                {{ $t('zaikoEpcList.label.colorName') }}{{ productInfo.colorName }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                {{ $t('zaikoEpcList.label.sizeCode') }}{{ productInfo.sizeCode }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                {{ $t('zaikoEpcList.label.sizeName') }}{{ productInfo.sizeName }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header" @click.stop="tableSort(header)" :style="cursorCheck(header)">
                      {{ $t("zaikoEpcList.label.table." + header) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no" @click="rowClick(data)" :style="cursorCheck(null)">
                    <td v-for="header in headers" :key="header">{{ data[header] }}</td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                      
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ dataList.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-container>
        <ErrorDialog v-if="dataError" :URL="previousURL"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import ErrorDialog from "@/components/dialog/ErrorDialog";

export default {
  components: {AppWrapperView, ErrorDialog},
  computed: {
    
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.dataList.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.dataList.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }
      tempArray = tempArray.map((x, index) => {
        // 使わないけど一応
        //x.no = index + 1;
        x.updateDatetime = utils.putSplitWordToCalendarWithTime(x.updateDatetime);

        return x;
      })
      return tempArray;
    },

    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },

    headers() {
      return this.$getTableDef("ZaikoEpcListPage");
    }
  },
  data: () => ({
    currentPageNo: 1,
    dataError: false,
    //１ページ表示数
    displayPerPage: {},
    displayPerPageItem: [],

    //ソートの状態
    sortState: {target: "", ascent: true},

    dataList: [],

    productInfo:{
      shopCode: "",
      janCode: "",
      productName :"",
      productCode :"",
      colorCode :"",
      colorName :"",
      sizeCode :"",
      sizeName :"",
    },

    pagination: {
      page: 1,
      itemsPerPage: 15
    },

    // エラー時の遷移先
    previousURL: Paths.ZAIKO_JAN_LIST,

  }),
  methods: {
    //カーソルの変更
    cursorCheck(value){
      return "cursor: pointer;"
    },
    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },

    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    rowClick(item) {
      store.setZaikoEpc(item.epc)
      router.push({name:Paths.ZAIKO_EPC_EVENT_LIST})
    },

    search() {

      api.post( "/zaiko/epc/search", {
          shopCode: this.productInfo.shopCode,
          janCode: this.productInfo.janCode
        } 
      )
      .then((response) => {
        if(!(response.data.results?.epcList?.length ?? 0 > 0)) this.dataError = true;
        this.productInfo.productName = response.data.results?.productName;
        this.productInfo.productCode = response.data.results?.productCode;
        this.productInfo.colorCode = response.data.results?.colorCode;
        this.productInfo.colorName = response.data.results?.colorName;
        this.productInfo.sizeCode = response.data.results?.sizeCode;
        this.productInfo.sizeName = response.data.results?.sizeName;
        this.dataList.splice(0, this.dataList.length, ...response.data.results?.epcList);
      })
      .catch((error) => {
        // back previous page
        this.dataError = true;
      });

    },
  },
  created() {
    //do nothing
  },
  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);
    if (!store.hasZaikoEpcParam()) {
      this.dataError = true;
    }
    else {
      this.productInfo.janCode = store.getZaikoJanCode();
      this.productInfo.shopCode = store.getZaikoShopCode();
    }
    this.search()
  }
};
</script>

<style></style>
