<template>
  <v-dialog v-model="dialog" width="680" @click:outside="cancel" :persistent="isLoading">
    <v-card>
      <v-card-title>
        <h3><strong>{{ $t('tanaoroshiResultCsvDownloadDialog.label.title') }}</strong></h3>
      </v-card-title>
      <v-card-text>
        <span class="ml-4" v-if="errorMessage" style="color: red">
          {{ errorMessage }}
        </span>
        <div class="ml-4" v-for="data in errorList" :key="data.message" style="color: red">
          {{ data.message }}
        </div>

        <v-container>
          <v-row>
            <v-col cols="12">
              <YearMonthPicker ref="nyukaFrom" v-model="searchCondition.selectedDate"
                :label="$t('tanaoroshiResultCsvDownloadDialog.label.selectedDate')"></YearMonthPicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select v-model="searchCondition.selectedShop" :items="shopOptions" density="compact" hide-details="auto"
                :label="$t('tanaoroshiResultCsvDownloadDialog.label.selectedShop')" :multiple="false" item-value="code" item-title="code_name" :error-messages="errors"
                return-object></v-select>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" :disabled="isLoading"> {{ $t('common.action.cancel') }} </v-btn>
        <v-btn color="indigo darken-1" dark @click="ok" :loading="isLoading" :disabled="!isFormValid()">
          {{ $t('common.action.download') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/apis/staff";
import constant from "@/utils/constant";
import YearMonthPicker from "@/components/YearMonthPicker";
import utils from "@/utils";

export default {
  components: { YearMonthPicker },
  props: {
    dialogData: {
      type: Object,
      required: true,
    },
    handleOk: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    },
  },


  computed: {
    shopOptions() {
      return this.dialogData.shopItem;
    },
  },


  data() {
    return {
      dialog: true,
      isLoading: false,
      errorMessage: "",
      errorList: [],
      searchCondition: {
        selectedDate: null,
        selectedShop: null,
      },
    };
  },


  methods: {
    isFormValid() {
      return this.searchCondition.selectedDate && this.searchCondition.selectedShop;
    },
    cancel() {
      if (this.isLoading) return;
      this.handleCancel();
    },
    ok() {
      this.isLoading = true;
      api.post("/tanaoroshi/download-csv-monthly", {
        siteCode: this.searchCondition.selectedShop.code,
        targetYearMonth: this.searchCondition.selectedDate.replaceAll("-", ""),
      })
        .then((response) => {
          if (response.data?.status == 0) {
            this.errorMessage = "";
            this.errorList = [];
            this.downloadCsv(response.data.results.csvRecordList);
          } else {
            this.errorMessage = response.data?.errorMessage;
            this.errorList = response.data?.errorList;
          }
        })
        .catch(() => {
          this.errorMessage = this.$t('common.message.unknownError');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    downloadCsv(dataList) {
      let dataArr = [];
      dataList.forEach(function (v, idx) {
        dataArr.push(v.join(",") + "\r\n")
      })
      let stamp = utils.csvTimeStamp(new Date())
      const prefix = this.$t('tanaoroshiResultCsvDownloadDialog.fileNamePrefix.csv');
      utils.csvDownload(dataArr.join(""), `${prefix}${stamp}`)
    },
  },
};
</script>
