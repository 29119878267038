<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="3">
              <v-select density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.siteCode"
                :items="siteCodeList" item-title="code_name" item-value="code" :label="$t('zaikoMove.label.siteCode')" :clearable="false"
                return-object />
            </v-col>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.janCode"
              :label="$t('zaikoMove.label.janCode')" />
            </v-col>
            <v-col cols="3">
              <v-select density="compact" variant="outlined" hide-details="auto"
                v-model="searchCondition.originSitezoneCode" :items="zoneItem" item-title="name" item-value="code"
                :label="$t('zaikoMove.label.originSitezoneCode')" :clearable="true" />
            </v-col>
            <v-col cols="3">
              <v-select density="compact" variant="outlined" hide-details="auto"
                v-model="searchCondition.destinationSitezoneCode" :items="zoneItem" item-title="name" item-value="code"
                :label="$t('zaikoMove.label.destinationSitezoneCode')" :clearable="true" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto"
                v-model="searchCondition.productNumber" :label="$t('zaikoMove.label.productNumber')" />
            </v-col>
            <v-col cols="3">
              <DatePicker ref="movingDateFrom" v-model="searchCondition.movingDateFrom" :label="$t('zaikoMove.label.movingDateFrom')"></DatePicker>
            </v-col>
            <v-col cols="3">
              <DatePicker ref="movingDateTo" v-model="searchCondition.movingDateTo" :label="$t('zaikoMove.label.movingDateTo')"></DatePicker>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-btn text @click="clear">{{ $t('common.action.clear') }}</v-btn>
              <v-btn theme="dark" class="ml-4 px-8 bg-grey-darken-3" elevation="2" @click="search"
                :loading="isSearching">{{ $t('common.action.search') }}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header" @click.stop="tableSort(header)"
                      :style="cursorCheck(header)">{{ $t("zaikoMove.label.table." + header) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no" @click="rowClick(data)" :style="cursorCheck(null)">
                    <template v-for="header in headers" :key="header">
                      <td>{{ data[header] }}</td>
                    </template>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination v-model="currentPageNo" :length="maxPageNo" />
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select density="compact" variant="outlined" hide-details="auto" v-model="displayPerPage"
                    :items="displayPerPageItem" item-title="name" item-value="value" :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue="resetCondition()" return-object />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ dataList.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn dark class="px-8 bg-indigo-darken-1" elevation="2" @click.prevent="downloadCsv"
                :disabled="dataList.length == 0">{{ $t('common.action.outputCsv') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog" />
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import DatePicker from "@/components/DatePicker";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: { AppWrapperView, DatePicker, ZeroData },
  props: {},


  computed: {

    // ページング情報の返却
    displayContentNo() {
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if (maxNo > this.dataList.length || this.displayPerPage.value == 0) {
        return { minNo: minNo, maxNo: this.dataList.length };
      }
      return { minNo: minNo, maxNo: maxNo };
    },

    // 最大ページ
    maxPageNo() {
      if (this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length / this.displayPerPage.value);
    },

    // ソート
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if (this.sortState.target != "") {
        tempArray = tempArray.sort(this.defaultSorter(this.sortState.ascent));
      }
      return tempArray;
    },
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo - 1, this.displayContentNo.maxNo);
    },
    // ゾーンリスト
    zoneItem() {
      const selectedSite = this.siteCodeList.find(x => x.code == this.searchCondition?.siteCode?.code);
      return selectedSite?.zoneList || [];
    },
    // ヘッダ
    headers() {
      return this.$getTableDef("ZaikoMovePage");
    }
  },


  data: () => ({
    currentPageNo: 1,
    prevRoutePath: "",
    // 1ページ表示数
    displayPerPage: {},
    displayPerPageItem: [],
    // ソートの状態
    sortState: { target: "", ascent: true },
    siteCodeList: [],
    dataList: [],

    // 検索条件
    searchCondition: {
      siteCode: "",
      janCode: "",
      originSitezoneCode: null,
      destinationSitezoneCode: null,
      productNumber: "",
      movingDateFrom: "",
      movingDateTo: "",
    },
    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,
  }),


  methods: {
    // 汎用的なソート関数を返却する
    defaultSorter(isAscent) {
      return (a, b) => {
        const multiplier = isAscent ? 1 : -1;
        if (a[this.sortState.target] > b[this.sortState.target]) return 1 * multiplier;
        if (a[this.sortState.target] < b[this.sortState.target]) return -1 * multiplier;
        return 0;
      }
    },
    // カーソル表示形式を変更
    cursorCheck(value) {
      return "cursor: pointer;"
    },

    // 店舗検索リスト取得
    getValidShopSearchList() {
      // selectSiteFlag：0：他店舗含む / 1：自店舗のみ
      api.post("/store/search", { selectSiteFlag: 0 })
        .then((response) => {
          if (response.status == 1) return;
          response.data.results?.shopList.forEach(element => {
            this.siteCodeList.push({
              name: element.name,
              code: element.code,
              zoneList: element.zoneList,
              code_name: element.code + '_' + element.name
            })
          })
          if (this.prevRoutePath == Paths.ZAIKO_MOVE_DETAIL) {
            this.searchCondition.siteCode = this.siteCodeList.find(e => e.code == this.searchCondition.siteCode.code);
            return;
          }
          this.searchCondition.siteCode = this.siteCodeList[0];
        })
        .catch((error) => {
          //do nothing
        })
    },

    // テーブルの条件リセット
    resetCondition() {
      this.currentPageNo = 1;
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if (headValue == "no") return;
      this.currentPageNo = 1;
      if (this.sortState.target == headValue) {
        this.sortState.ascent = !this.sortState.ascent;
      } else {
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    // 入荷明細画面へ遷移
    rowClick(item, row) {
      store.setZaikoMovingResultSelectedItem(JSON.stringify(item));
      router.push({ name: Paths.ZAIKO_MOVE_DETAIL })
    },

    clear() {
      this.$refs.movingDateFrom.clear()
      this.$refs.movingDateTo.clear()
      this.searchCondition = {
        siteCode: this.siteCodeList[0],
        janCode: "",
        originSitezoneCode: null,
        destinationSitezoneCode: null,
        productNumber: "",
        movingDateFrom: "",
        movingDateTo: "",
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    search() {
      this.isSearching = true;
      try {
        store.setZaikoMovingSearchCondition(JSON.stringify(this.searchCondition));
      } catch (e) {
        // do nothing
      }
      api.post("/zaiko/movement-actual/search", {
        siteCode: this.searchCondition.siteCode?.code ?? "",
        janCode: this.searchCondition.janCode,
        originSitezoneCode: this.searchCondition.originSitezoneCode ?? "",
        destinationSitezoneCode: this.searchCondition.destinationSitezoneCode ?? "",
        productNumber: this.searchCondition.productNumber,
        movingDateFrom: this.searchCondition.movingDateFrom?.replaceAll("-", "") ?? "",
        movingDateTo: this.searchCondition.movingDateTo?.replaceAll("-", "") ?? "",
      }).then((response) => {
        this.dataList = response.data.results?.zaikoMovementActualResultList ?? [];
        this.resetCondition();

        // 0件ダイアログ処理
        if (this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      }).catch((error) => {
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      });
    },
    // CSVダウンロード処理
    downloadCsv() {
      utils.simpleDownloadCsv(this.headers, this.sortedTable, "zaikoMove.label.table.", 'zaikoMove.fileNamePrefix.csv', this.$t);
    },
    initFromDetail() {
      this.searchCondition = JSON.parse(store.getZaikoMovingSearchCondition());
      if (this.searchCondition.movingDateFrom) this.$refs.movingDateFrom.setDate(new Date(this.searchCondition.movingDateFrom));
      if (this.searchCondition.movingDateTo) this.$refs.movingDateTo.setDate(new Date(this.searchCondition.movingDateTo));
      this.search();
    },
  },
  created() {
    this.getValidShopSearchList()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoutePath = from?.path;
    })
  },
  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);
    if (this.prevRoutePath == Paths.ZAIKO_MOVE_DETAIL) {
      this.initFromDetail();
      return;
    }
  }
};
</script>

<style></style>
