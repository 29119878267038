<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                theme="dark"
                class="ml-4 px-8 bg-grey-darken-3"
                elevation="2"
                @click="returnbutton"
                >{{ $t('common.action.back') }}</v-btn
              >
            </v-col>
          </v-row> 
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <template v-for="header in headers" :key="header">
                      <th v-if="header.indexOf('spareItem') == -1" @click.stop="tableSort(header)" :style="cursorCheck(header)">
                        {{ $t("shukkaDetail.label.table." + header) }}</th>
                      <th v-else @click.stop="tableSort(header)" :style="cursorCheck(header)">
                        {{ this.getSpareItemLabel(header) }}</th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <template v-for="header in headers" :key="header">
                      <template v-if="header == 'comment'">
                        <template v-if="data[header]">
                          <v-tooltip location-strategy="connected" location="start top" origin="end top" scroll-strategy="reposition" :width="calcTooltipWidth(data[header])">
                            <template v-slot:activator="{ props }">
                              <td v-bind="props" :style="cursorCheck()" >{{ $t('shukkaDetail.label.hasComment') }}</td>
                            </template>
                            <p style="word-break: break-all;">
                                {{ data[header] }}
                            </p>
                          </v-tooltip>
                        </template>
                        <td v-else></td>
                      </template>

                      <template v-else>
                        <td>{{ convertListData(data, header) }}</td>
                      </template>

                    </template>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                      
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ dataList.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                dark
                class="px-8 bg-indigo-darken-1"
                elevation="2"
                @click="downloadCsv"
                :disabled="dataList.length == 0"
                >{{ $t('common.action.outputCsv') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
//import DatePicker from "@/components/DatePicker";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import constant from "@/utils/constant";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  //components: {AppWrapperView, DatePicker, ZeroData},
  components: {AppWrapperView, ZeroData},
  props: {},
  computed: {
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.dataList.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.dataList.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      tempArray = tempArray.map((x, index) => {
        x.shukkaDate = utils.putSplitWordToCalendar(x.shukkaDate);
        x.startDate = utils.getCalendarStr(new Date(x.startDate));
        x.endDate = utils.getCalendarStr(new Date(x.endDate));
        x.no = index + 1;
        return x;
      })

      return tempArray;
    },

    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },

    // ヘッダー
    headers() {
      const def = this.$getTableDef("ShukkaDetailPage");
      const ret = [];

      const spareItemMap = JSON.parse(store.getShukkaResultSpareItemMap());
      for (const item of def) {
        if (item.indexOf("spareItem") !== -1) {
          const key = item.replace("spareItem", "");
          if (!spareItemMap[key]?.label) {
            continue;
          }
        }
        ret.push(item);
      }
      return ret;
    },

  },

  data: () => ({

    calcTooltipWidth(str) {
      var length = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        if ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
          length += 0.8;
        } else {
          length += 1.2;
        }
      }
      return length >= constant.COMMENT_MAX_WIDTH ? constant.COMMENT_MAX_WIDTH+"em" : length+"em";
    },

    currentPageNo: 1,

    //１ページ表示数
    displayPerPage: {},
    displayPerPageItem: [],

    //ソートの状態
    sortState: {target: "", ascent: true},

    shukkamotoItem: [],
    shukkasakiItem: [],

    dataList: [],

    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,
    spareItemMap: {},
  }),
  
  methods: {

    // カーソルの変更
    cursorCheck(value){
      return "cursor: pointer;"
    },

    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    // 出荷実績明細取得
    //${api.basePath}/shop/${store.ownData.getters.currentShopId()}/nyuuka/scm
    search() {
      this.isSearching = true;
      api.post( "/shukka/actual/detail/search", {
          managementId: store.getShukkaManagementId(),
          lineNo: store.getShukkaLineNumber(),
          denpyoNumber: store.getShukkaDenpyoNumber()
        } 
      )
      .then((response)=>{
        this.dataList = response.data.results?.shukkaList ?? [];
        this.resetCondition();

        // 0件ダイアログ処理
        if(this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;

      })
      .catch((error) => {
        // 0件 dialog
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
    },

    // 戻るボタン
    returnbutton() {
      // 伝票番号
      router.push({name:Paths.SHUKKA})
    },
    downloadCsv() {
      const dataArr = []

      // ヘッダー部
      const header = [];
      for (const h of this.headers) {
        if( h.indexOf('spareItem') == -1) {
          header.push(this.$t("shukkaDetail.label.table." + h));
        } else {
          header.push(this.getSpareItemLabel(h));
        }
      }
      dataArr.push(header.join(",") + "\r\n");

      // データ部
      for (const row of this.sortedTable) {
        let arr = [];
        for (const key of this.headers) {
          arr.push(this.convertListData(row, key));
        }
        dataArr.push(arr.join(",") + "\r\n");
      }

      let stamp = utils.csvTimeStamp(new Date())
      const prefix = this.$t('shukkaDetail.fileNamePrefix.detailCsv');
      utils.csvDownload(dataArr.join(""), `${prefix}${stamp}`)
    },
    convertListData(data, headerVal) {

      const rawVal = data[headerVal];

      // 予備項目系
      if (headerVal.indexOf("spareItem") != -1) {
        var spareItemMap = JSON.parse(store.getShukkaResultSpareItemMap());
        var spareItemInfo = spareItemMap[headerVal.replaceAll("spareItem", "")];

        if (!spareItemInfo || !spareItemInfo.convertList) return rawVal;

        const convInfo = spareItemInfo.convertList.find(e => e.code == data[headerVal]);
        if (!convInfo) return rawVal;

        return convInfo.label;
      }
      return rawVal;
    },
    getSpareItemLabel(itemKey) {
      const key = itemKey.replace("spareItem", "");
      return this.spareItemMap[key]?.label;
    },

  },
  created() {
    //do nothing
  },
  
  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);
    this.spareItemMap = JSON.parse(store.getShukkaResultSpareItemMap());
    this.search()
  }
};
</script>

<style>

</style>
