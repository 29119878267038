<template>
  <v-dialog v-model="dialogmodel" width="500" persistent>
    <v-card>
      <v-card-text class="pa-4 pre-line grey--text text--darken-4 text-subtitle-1 px-5">
        <p class="mb-4">
          {{ this.dialogData.message }}
        </p>
        <div v-for="data in this.dialogData.errorList" :key="data.message">
          {{ data.message }}
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn color="indigo darken-1" dark @click="ok">{{ $t('common.action.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
// 汎用的なメッセージダイアログ
// dialogData.message   メインのメッセージ
// dialogData.errorList エラー内容のリスト。メインメッセージ下部に列挙される。
export default {
  props: {
    handleOk: {
      type: Function,
      required: true,
    },
    dialogData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogmodel: true,
    };
  },
  methods: {
    ok() {
      this.handleOk();
    },
  },
};
</script>
<style>
.pre-line {
  white-space: pre-line;
}
</style>
