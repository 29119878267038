import { App } from "vue";

class _TableDefinitionManager {

    /** デフォルト定義 */
    private defaultDefinition;

    /** 会社用定義 */
    private companyDefinition;

    constructor() {

        // デフォルト定義読込
        this.defaultDefinition = require("@/plugins/setting/table/default.json");

        try {
            // 会社用定義読込
            const company = process.env.VUE_APP_COMPANY;
            this.companyDefinition = require(`@/plugins/setting/table/${company}.json`);
        } catch (e) {
            // エラーの場合は、デフォルト定義を設定
            this.companyDefinition = this.defaultDefinition;
        }
    }

    /**
     * テーブル定義取得
     * @param tag タグ
     * @returns 
     */
    get(tag: string): string[] {
        // 会社用に見つからなければデフォルトにフォールバック
        return this.companyDefinition[tag] || this.defaultDefinition[tag];
    }
}

function getTableDef() {
    const manager = new _TableDefinitionManager();
    function getTableDef(tag: string) {
        return manager.get(tag);
    }
    return getTableDef;
}

export const TableDefinitionManager = {
    install: (app: App) => {
        app.config.globalProperties.$getTableDef = getTableDef();
    }
};