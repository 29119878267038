<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.denpyouBangou"
                :label="$t('shukka.label.denpyouBangou')"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.shoriKubun"
                :label="$t('shukka.label.shoriKubun')"
                :items="shoriKbnItem"
                item-title="label"
                item-value="key"
                clearable
                return-object
              ></v-select>
            </v-col>
            <template v-if="isCustomSearchMode">
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.janCode"
                  :label="$t('shukka.label.janCode')"
              />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.productNumber"
                  :label="$t('shukka.label.productNumber')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.colorCode"
                  :label="$t('shukka.label.colorCode')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.sizeCode"
                  :label="$t('shukka.label.sizeCode')"
                />
              </v-col>
            </template>
            <v-col cols="3">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.shukkamoto"
                :items="shukkamotoItem"
                item-title="code_name"
                item-value="code"
                :label="$t('shukka.label.shukkamoto')"
                return-object
              />
            </v-col>
            <v-col cols="3">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                v-model="searchCondition.shukkasaki"
                :items="shukkasakiItem"
                item-title="code_name"
                item-value="code"
                :label="$t('shukka.label.shukkasaki')"
                :clearable="true"
                return-object
              />
            </v-col>
            <v-col cols="3">
              <DatePicker
                ref="shukkaFrom"
                v-model="searchCondition.shukkaDateFrom"
                :label="$t('shukka.label.shukkaDateFrom')"
              ></DatePicker>
            </v-col>
            <v-col cols="3">
              <DatePicker
                ref="shukkaTo"
                v-model="searchCondition.shukkaDateTo"
                :label="$t('shukka.label.shukkaDateTo')"
              ></DatePicker>
            </v-col>
            
            <template v-for="n in 10" :key="n">
              <v-col cols="3" v-if="spareItemMap && spareItemMap[n] && spareItemMap[n].searchable">
                <template v-if="spareItemMap[n].convertList">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="$data['searchCondition']['spareItem' + n]"
                    :items="spareItemMap[n].convertList"
                    item-title="label"
                    item-value="code"
                    :label="spareItemMap[n].label"
                    :clearable="true"
                    return-object
                  />
                </template>
                <template v-else>
                  <v-text-field
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="$data['searchCondition']['spareItem' + n]"
                    :label="spareItemMap[n].label"
                  />
                </template>
              </v-col>
            </template>

             <v-col cols="searchAndClearBtnColWidth" class="text-right">
              <v-btn text @click="clear">{{ $t('common.action.clear') }}</v-btn>
              <v-btn
                theme="dark"
                class="ml-4 px-8 bg-grey-darken-3"
                elevation="2"
                @click.prevent="search"
                :loading="isSearching"
                >{{ $t('common.action.search') }}</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header" @click.stop="tableSort(header)" :style="cursorCheck(header)">
                      {{ $t("shukka.label.table." + header) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no" @click="rowClick(data)" :style="cursorCheck(null)" >
                    <td v-for="header in headers" :key="header">{{ data[header] }}</td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                      
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ dataList.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                dark
                class="px-8 bg-indigo-darken-1"
                elevation="2"
                @click="downloadCsv"
                :disabled="dataList.length == 0"
                >{{ $t('common.action.outputCsv') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import DatePicker from "@/components/DatePicker";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import constant from "@/utils/constant";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: {AppWrapperView, DatePicker, ZeroData},
  props: {},
  computed: {
    isCustomSearchMode() {
      return process.env.VUE_APP_IS_CUSTOM_SEARCH_MODE_ON_SHUKKA;
    },
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.dataList.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.dataList.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      tempArray = tempArray.map((x, index) => {
        x.shukkaDate = utils.putSplitWordToCalendar(x.shukkaDate);
        x.startDate = utils.getCalendarStr(new Date(x.startDate));
        x.endDate = utils.getCalendarStr(new Date(x.endDate));
        x.no = index + 1;
        return x;
      })

      return tempArray;
    },

    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },
    shoriKbnItem() {
      const list = [];
      for (const e of JSON.parse(store.getShippedKbnList()).filter(e => e.isAvailable)) {
        list.push({label: this.$t("common.label.shippedKbn." + e.key), ...e});
      }
      return list;
    },
    spareItemMap() {
      const mapStr = store.getShukkaResultSpareItemMap();
      if (!mapStr) return {};
      return JSON.parse(mapStr);
    },
    searchAndClearBtnColWidth() {
      // NOTE:検索とクリアボタンを右端に表示するため、ボタンを保持するカラムの横幅は(12 - 列に存在する検索項目数 * 3) とする。
      const shukkaResultSpareItemMap = JSON.parse(store.getShukkaResultSpareItemMap());
      let items = 6; // 固定の検索項目数
      if (shukkaResultSpareItemMap) {
        for (const key of Object.keys(shukkaResultSpareItemMap)) {
          if (shukkaResultSpareItemMap[key]?.searchable) items++;
        }
      }
      return 12 - (items % 4 * 3);
    },
    // ヘッダー
    headers() {
      return this.$getTableDef("ShukkaPage");
    },
  },

  data: () => ({

    calcTooltipWidth(str) {
      var length = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        if ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
          length += 0.8;
        } else {
          length += 1.2;
        }
      }
      return length >= constant.COMMENT_MAX_WIDTH ? constant.COMMENT_MAX_WIDTH+"em" : length+"em";
    },

    currentPageNo: 1,
    prevRoutePath: "",
    //１ページ表示数
    displayPerPage: {},
    displayPerPageItem: [],

    //ソートの状態
    sortState: {target: "", ascent: true},

    shukkamotoItem: [],
    shukkasakiItem: [],

    dataList: [],
    searchCondition: {
      denpyouBangou: "",    //伝票番号
      shoriKubun: "",   //出荷区分
      shukkamoto: null,  //出荷元店舗
      shukkasaki: null, //出荷先店舗
      shukkaDateFrom: "",  //出荷日from
      shukkaDateTo: "", //出荷日to
      janCode: "",      // JANコード
      productNumber: "",// 品番
      colorCode: "",    // カラーコード
      sizeCode: "",     // サイズコード
      spareItem1: null,
      spareItem2: null,
      spareItem3: null,
      spareItem4: null,
      spareItem5: null,
      spareItem6: null,
      spareItem7: null,
      spareItem8: null,
      spareItem9: null,
      spareItem10:null,
    },

    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,

  }),
  
  methods: {

    // カーソルの変更
    cursorCheck(value){
      return "cursor: pointer;"
    },
    // 店舗検索リスト取得
    // selectSiteFlag：0：他店舗含む / 1：自店舗のみ
    getValidShopSearchList(){
      //出荷元店舗リスト取得
      api.post("/store/search", {selectSiteFlag: 1})
      .then((response)=>{
        if(response.status == 1) return; //do nothing
        response.data.results?.shopList.forEach(element => {
          this.shukkamotoItem.push({name: element.name, code: element.code, code_name: element.code+'_'+element.name}) 
        })
        if (this.prevRoutePath == Paths.SHUKKA_DETAIL) {
          this.searchCondition.shukkamoto = this.shukkamotoItem.find(e => e.code == this.searchCondition.nyukasaki.code);
          return;
        }
        if (this.isNavigatedFromDashboardPanel()) {
          this.searchCondition.shukkamoto = this.shukkamotoItem.find(e => e.code == store.getDashboardSiteCode());
          return;
        }
        this.searchCondition.shukkamoto = this.shukkamotoItem[0];
      })
      .catch((error) => {
        //do nothing
      })
      
      //出荷先店舗リスト取得
      api.post("/store/search", {selectSiteFlag: 0})
      .then((response)=>{
        if(response.status == 1) return; //do nothing
        response.data.results?.shopList.forEach(element => {
          this.shukkasakiItem.push({name: element.name, code: element.code, code_name: element.code+'_'+element.name})
        })
      })
      .catch((error) => {
        //do nothing
      })
      
    },

    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    // 出荷明細画面へ遷移
    rowClick(item, row) {
      // 伝票番号 管理ID 管理ID内行番号
      store.setShukkaDenpyoNumber(item.denpyoNumber);
      store.setShukkaManagementId(item.managementId);
      store.setShukkaLineNumber(item.lineNumber);
      router.push({name:Paths.SHUKKA_DETAIL})
    },

    clear() {
      this.$refs.shukkaTo.clear()
      this.$refs.shukkaFrom.clear()
      this.searchCondition = {
        denpyouBangou: "",    //伝票番号
        shoriKubun: "",   //出荷区分
        shukkamoto: this.shukkamotoItem[0],  //出荷元店舗
        shukkasaki: null, //出荷先店舗
        //shukkaDateFrom: "",  //出荷日from
        //shukkaDateTo: "", //出荷日to
        janCode: "",      // JANコード
        productNumber: "",// 品番
        colorCode: "",    // カラーコード
        sizeCode: "",     // サイズコード
        spareItem1: null,
        spareItem2: null,
        spareItem3: null,
        spareItem4: null,
        spareItem5: null,
        spareItem6: null,
        spareItem7: null,
        spareItem8: null,
        spareItem9: null,
        spareItem10:null,
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },
    
    search() {
      this.isSearching = true;
      try {
        store.setShukkaSearchCondition(JSON.stringify(this.searchCondition));
      } catch (e) {
        console.log(e);
      }
      const getSpareItemVal = (obj) => {
        if (typeof obj == 'object') {
          return obj?.code ?? ""
        }
        return obj;
      };
      api.post("/shukka/actual/search", {
          denpyoNumber: this.searchCondition.denpyouBangou,
          syoriKubun: this.searchCondition.shoriKubun?.key ?? "",
          shukkamotoCode: this.searchCondition.shukkamoto?.code ?? "",
          shukkasakiCode: this.searchCondition.shukkasaki?.code ?? "",
          shukkaDateFrom: this.searchCondition.shukkaDateFrom?.replaceAll("-","") ?? "",
          shukkaDateTo: this.searchCondition.shukkaDateTo?.replaceAll("-","") ?? "",
          janCode: this.searchCondition.janCode,
          productNumber: this.searchCondition.productNumber,
          colorCode: this.searchCondition.colorCode,
          sizeCode: this.searchCondition.sizeCode,
          spareItem1: getSpareItemVal(this.searchCondition.spareItem1),
          spareItem2: getSpareItemVal(this.searchCondition.spareItem2),
          spareItem3: getSpareItemVal(this.searchCondition.spareItem3),
          spareItem4: getSpareItemVal(this.searchCondition.spareItem4),
          spareItem5: getSpareItemVal(this.searchCondition.spareItem5),
          spareItem6: getSpareItemVal(this.searchCondition.spareItem6),
          spareItem7: getSpareItemVal(this.searchCondition.spareItem7),
          spareItem8: getSpareItemVal(this.searchCondition.spareItem8),
          spareItem9: getSpareItemVal(this.searchCondition.spareItem9),
          spareItem10:getSpareItemVal(this.searchCondition.spareItem10),
        }
      )
      .then((response)=>{
        this.dataList = response.data.results?.shukkaList ?? [];
        this.resetCondition();

        // 0件ダイアログ処理
        if(this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
      .catch((error) => {
        // 0件 dialog
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
    },

    downloadCsv() {
      utils.simpleDownloadCsv(this.headers, this.sortedTable, "shukka.label.table.", 'shukka.fileNamePrefix.csv', this.$t);
    },
    initFromDetail() {
      this.searchCondition = JSON.parse(store.getShukkaSearchCondition());
      if (this.searchCondition.shukkaDateFrom) this.$refs.shukkaFrom.setDate(new Date(this.searchCondition.shukkaDateFrom));
      if (this.searchCondition.shukkaDateTo) this.$refs.shukkaTo.setDate(new Date(this.searchCondition.shukkaDateTo));
      this.search();
    },
    initFromDashboard() {
      const searchDate = new Date(store.getDashboardSearchDate());
      this.$refs.shukkaFrom.setDate(searchDate);
      this.$refs.shukkaTo.setDate(searchDate);
      this.searchCondition.shoriKubun = this.shoriKbnItem.filter(e => e.key == store.getDashboardShukkaKbn())[0];
      this.searchCondition.shukkamoto = {code: store.getDashboardSiteCode()};
      this.search();
    },
    isNavigatedFromDashboardPanel() {
      // NOTE
      // "遷移元がダッシュボード"という条件だけだと、メニューのリンクを押下しても遷移イベントが発生する。
      // パネル押下時にセッションに店舗コードを設定するので、それの有無でパネルからの遷移を判断する。
      return this.prevRoutePath == Paths.DASHBOARD && store.getDashboardSiteCode();
    },
  },
  created() {
    this.getValidShopSearchList()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoutePath = from?.path;
    })
  },
  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);

    if (this.prevRoutePath == Paths.SHUKKA_DETAIL) {
      this.initFromDetail();
      return;
    }
    if (this.isNavigatedFromDashboardPanel()) {
      this.initFromDashboard();
      return;
    }
  }
};
</script>

<style>

</style>
