<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn theme="dark" class="ml-4 px-8 bg-grey-darken-3" elevation="2" @click="returnbutton">{{ $t('common.action.back') }}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header" @click.stop="tableSort(header)"
                      :style="cursorCheck(header)">{{ $t("zaikoMoveDetail.label.table." + header) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <td v-for="header in headers" :key="header">{{ data[header] }}
                    </td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination v-model="currentPageNo" :length="maxPageNo" />
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select density="compact" variant="outlined" hide-details="auto" v-model="displayPerPage"
                    :items="displayPerPageItem" item-title="name" item-value="value" :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue="resetCondition()" return-object />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ dataList.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn dark class="px-8 bg-indigo-darken-1" elevation="2" @click.prevent="downloadCsv"
                :disabled="dataList.length == 0">{{ $t('common.action.outputCsv') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog" />
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: { AppWrapperView, ZeroData },
  props: {},
  computed: {
    //どの部分を表示しているのか
    displayContentNo() {
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if (maxNo > this.dataList.length || this.displayPerPage.value == 0) {
        return { minNo: minNo, maxNo: this.dataList.length };
      }
      return { minNo: minNo, maxNo: maxNo };
    },

    //最大ページ
    maxPageNo() {
      if (this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length / this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if (this.sortState.target != "") {
        tempArray = tempArray.sort(this.defaultSorter(this.sortState.ascent));
      }
      return tempArray;
    },
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo - 1, this.displayContentNo.maxNo);
    },
    // ヘッダ
    headers() {
      return this.$getTableDef("ZaikoMoveDetailPage");
    }
  },


  data: () => ({
    currentPageNo: 1,
    displayPerPage: {},
    displayPerPageItem: [],
    //ソートの状態
    sortState: { target: "", ascent: true },

    dataList: [],
    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,
  }),


  methods: {
    // 汎用的なソート関数を返却する
    defaultSorter(isAscent) {
      return (a, b) => {
        const multiplier = isAscent ? 1 : -1;
        if (a[this.sortState.target] > b[this.sortState.target]) return 1 * multiplier;
        if (a[this.sortState.target] < b[this.sortState.target]) return -1 * multiplier;
        return 0;
      }
    },
    //カーソルの変更
    cursorCheck(value) {
      return "cursor: pointer;"
    },

    //テーブルの条件リセット
    resetCondition() {
      this.currentPageNo = 1;
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if (headValue == "no") return;
      this.currentPageNo = 1;
      if (this.sortState.target == headValue) {
        this.sortState.ascent = !this.sortState.ascent;
      } else {
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    search() {
      this.isSearching = true;
      const item = JSON.parse(store.getZaikoMovingResultSelectedItem());
      api.post("/zaiko/movement-actual/epc/search", {
        movingDate: item.movingDate?.replaceAll("-", ""),
        siteCode: item.siteCode,
        originSitezoneCode: item.originSitezoneCode,
        destinationSitezoneCode: item.destinationSitezoneCode,
        janCode: item.janCode,
        transactionDivision: item.transactionDivision,
      }).then((response) => {
        this.dataList = response.data.results?.zaikoEpcMovementActualResultList ?? [];
        this.resetCondition();
        if (this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      }).catch((error) => {
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
    },

    // 戻るボタン
    returnbutton() {
      router.push({ name: Paths.ZAIKO_MOVE })
    },
    // CSVダウンロード処理
    downloadCsv() {
      utils.simpleDownloadCsv(this.headers, this.sortedTable, "zaikoMoveDetail.label.table.", 'zaikoMoveDetail.fileNamePrefix.csv', this.$t);
    },
  },
  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);
    this.search();
  }
};
</script>

<style></style>
