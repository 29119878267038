<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                theme="dark"
                class="ml-4 px-8 bg-grey-darken-3"
                elevation="2"
                @click="returnbutton"
                >{{ $t('common.action.back') }}</v-btn
              >
            </v-col>
          </v-row> 
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <template v-for="header in headers" :key="header">
                      <th v-if="header.indexOf('spareItem') == -1" @click.stop="tableSort(header)" :style="cursorCheck(header)">
                        {{ $t("nyuukaDetail.label.table." + header) }}</th>
                      <th v-else @click.stop="tableSort(header)" :style="cursorCheck(header)">
                        {{ this.getSpareItemLabel(header) }}</th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <td v-for="header in headers" :key="header" >{{ data[header] }}
                    </td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                    </td>
                  </tr>
                </tbody>  
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ dataList.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                dark
                class="px-8 bg-indigo-darken-1"
                elevation="2"
                @click.prevent="downloadCsv"
                :disabled="dataList.length == 0"
                >{{ $t('common.action.outputCsv') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
//import DatePicker from "@/components/DatePicker";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  //components: {AppWrapperView, DatePicker, ZeroData},
  components: {AppWrapperView, ZeroData},
  props: {},
  computed: {
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.dataList.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.dataList.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      tempArray = tempArray.map((x, index) => {
        x.nyukaDate = utils.putSplitWordToCalendar(x.nyukaDate);
        x.startDate = utils.putSplitWordToCalendar(x.startDate);
        x.endDate = utils.putSplitWordToCalendar(x.endDate);
        x.no = index + 1;
        return x;
      })

      return tempArray;
    },
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },

    // ヘッダー
    headers() {
      const def = this.$getTableDef("NyuukaDetailPage");
      const ret = [];

      const spareItemMap = JSON.parse(store.getNyuukaResultSpareItemMap());
      for (const item of def) {
        if (item.indexOf("spareItem") !== -1) {
          const key = item.replace("spareItem", "");
          if (!spareItemMap[key]?.label) {
            continue;
          }
        }
        ret.push(item);
      }
      return ret;
    },

  },
  data: () => ({
    currentPageNo: 1,

    //１ページ表示数
    displayPerPage: {},
    displayPerPageItem: [],
    //ソートの状態
    sortState: {target: "", ascent: true},

    nyukamotoItem: [],
    nyukasakiItem: [],

    dataList: [],

    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,
    spareItemMap: {}

  }),
  methods: {

    //カーソルの変更
    cursorCheck(value){
      return "cursor: pointer;"
    },

    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },
    
    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    // 入荷実績明細取得
    //${api.basePath}/shop/${store.ownData.getters.currentShopId()}/nyuuka/scm
    search() {
      this.isSearching = true;
      api.post( "/nyuka/actual/detail/search", {
          denpyoNumber: store.getNyukaDenpyoNumber()
        } 
      )
      .then((response)=>{
        this.dataList = response.data.results?.nyukaList ?? [];
        this.resetCondition();

        // 0件ダイアログ処理
        if(this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;

      })
      .catch((error) => {
        // 0件 dialog
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
    },

    // 戻るボタン
    returnbutton() {
      // 伝票番号
      router.push({name:Paths.NYUUKA})
    },
    getSpareItemLabel(itemKey) {
      const key = ("" + itemKey).replace("spareItem", "");
      return this.spareItemMap[key]?.label;
    },
    downloadCsv() {
      const dataArr = []

      // ヘッダー部
      const header = [];
      for (const h of this.headers) {
        if( h.indexOf('spareItem') == -1) {
          header.push(this.$t("nyuukaDetail.label.table." + h));
        } else {
          header.push(this.getSpareItemLabel(h));
        }
      }
      dataArr.push(header.join(",") + "\r\n");

      // データ部
      for (const row of this.sortedTable) {
        let arr = []
        this.headers.forEach(key => {arr.push(row[key])});
        dataArr.push(arr.join(",") + "\r\n");
      }

      let stamp = utils.csvTimeStamp(new Date())
      const prefix = this.$t('nyuukaDetail.fileNamePrefix.detailCsv');
      utils.csvDownload(dataArr.join(""), `${prefix}${stamp}`)
    },

  },
  created() {
    //do nothing
  },

  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);
    this.spareItemMap = JSON.parse(store.getNyuukaResultSpareItemMap());
    if (!store.hasNyukaDetailEventParam()) {
      this.dataError = true;
    }
    this.search()
  }

};
</script>

<style>

</style>
