import { createI18n } from "vue-i18n";
import defaultMessage from "@/plugins/setting/label/default.json";

let companyMessage = null;
try {
    const company = process.env.VUE_APP_COMPANY;
    companyMessage = require(`@/plugins/setting/label/${company}.json`);
} catch (e) {
    companyMessage = defaultMessage;
}
export const i18n = createI18n<[any], any>({
    locale: "companyMessage",
    fallbackLocale: 'defaultMessage',
    silentFallbackWarn: true,
    messages: {
        defaultMessage: defaultMessage,
        companyMessage: companyMessage
    },
});
