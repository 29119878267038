<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>

          <v-form @submit.prevent="search" ref="formRefs">
            <v-row>
              <v-col cols="3">
                <v-select
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.shopCode"
                  :items="shopItem"
                  item-title="code_name"
                  item-value="code"
                  :label="$t('zaikoJanList.label.shopCode')"
                  @update:modelValue="searchCondition.zoneCode = null"
                  :rules="requiredValidation"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.janCode"
                  :label="$t('zaikoJanList.label.janCode')"
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.zoneCode"
                  :items="zoneItem"
                  item-title="name"
                  item-value="code"
                  :label="$t('zaikoJanList.label.zoneCode')"
                  :clearable="true"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.typeCode"
                  :label="$t('zaikoJanList.label.typeCode')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.colorCode"
                  :label="$t('zaikoJanList.label.colorCode')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.sizeCode"
                  :label="$t('zaikoJanList.label.sizeCode')"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  v-model="searchCondition.brandCode"
                  :label="$t('zaikoJanList.label.brandCode')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn text @click="clear">{{ $t('common.action.clear') }}</v-btn>
                <v-btn
                  theme="dark"
                  class="ml-4 px-8 bg-grey-darken-3"
                  elevation="2"
                  type="submit"
                  :loading="isSearching"
                  >{{ $t('common.action.search') }}</v-btn
                >
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header" @click.stop="tableSort(header)" :style="cursorCheck(header)">
                      {{ $t("zaikoJanList.label.table." + header) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no" @click="rowClick(data)" :style="cursorCheck(null)">
                    <td v-for="header in headers" :key="header">{{ data[header] }}</td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length"></td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    :label="$t('common.label.displayCountPerPage')"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}{{ $t('common.label.displayedDataRange') }} / {{ dataList.length }}{{ $t('common.label.totalCountOf') }}
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                dark
                class="px-8 bg-indigo-darken-1"
                elevation="2"
                @click="downloadCsv"
                :disabled="dataList.length == 0"
                >{{ $t('common.action.outputCsv') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import api from "@/apis/staff";
import store from "@/store";
import utils from "@/utils";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: {AppWrapperView, ZeroData},
  props: {},
  computed: {
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.dataList.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.dataList.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      // 変換
      tempArray = tempArray.map((x, index) => {
        // noはなし
        // x.no = index + 1;
        x.rfidIntroduced = utils.replaceEpcFlagWord(x.rfidIntroduced);
        return x;
      })

      return tempArray;
    },

    // ページ区切り後のデータ
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },

    //ゾーンリスト
    zoneItem() {
      // searchConditionのshopCodeと同じcodeをチェックして、そのzoneを返す
      // 該当するものがない場合undefなのでnull返却
      const selectedShop = this.shopItem.find(x => x.code == this.searchCondition?.shopCode);

      if (selectedShop == null || selectedShop.zoneList == null) return [];
      return selectedShop.zoneList;
    },
    // ヘッダ
    headers() {
      return this.$getTableDef("ZaikoJanListPage");
    }

  },

  data: () => ({
    currentPageNo: 1,

    //１ページ表示数
    displayPerPage: {},
    displayPerPageItem: [],

    //ソートの状態
    sortState: {target: "", ascent: true},

    // 店の中にゾーンも含まれている
    // ゾーンはcomputedで別計算
    shopItem: [],


    dataList: [],
    searchCondition: {
      shopCode: null,         // 店舗コード
      janCode: "",            // JANコード
      zoneCode: null,         // ロケ(ゾーンCD)
      typeCode: "",           // 品番CD
      colorCode: "",          // カラーCD
      sizeCode: "",           // サイズCD
      brandCode: "",
    },

    // stringに対する必須チェック
    // 店舗は必須なため
    requiredValidation: [(value) => !!value || this.$t('zaikoJanList.message.required')],

    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,

  }),
  
  methods: {
    //カーソルの変更
    cursorCheck(value){
      return "cursor: pointer;"
    },

    // 店舗リストの取得
    // 自店舗検索リスト取得
    // selectSiteFlag：0：他店舗含む / 1：自店舗のみ
    getValidShopSearchList(){
      api.post("/store/search", {selectSiteFlag: 1})
      .then((response)=>{
        if(response.status == 1) return; //do nothing
        response.data.results?.shopList.forEach(element => {
          this.shopItem.push({name: element.name, code: element.code, zoneList: element.zoneList, code_name: element.code+'_'+element.name}) 
        })
        if (this.isNavigatedFromDashboardPanel()) {
          this.searchCondition.shopCode = store.getDashboardSiteCode();
          return;
        }
        // 先頭値を入れる
        if(this.shopItem.length > 0) this.searchCondition.shopCode = this.shopItem[0].code;

      })
      .catch((error) => {
        //do nothing
      })
    },

    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    rowClick(item, row) {
      store.setZaikoJanCode(item.janCode);
      store.setZaikoShopCode(item.shopCode);
      router.push({name:Paths.ZAIKO_EPC_LIST})
    },

    clear() {
      this.searchCondition = {
        shopCode: this.shopItem[0].code ?? null, // 店舗コード
        janCode: "",            // JANコード
        zoneCode: null,             // ロケ(ゾーンCD)
        typeCode: "",           // 品番CD
        colorCode: "",          // カラーCD
        sizeCode: "",           // サイズCD
        brandCode: "",  //ブランドCD
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    async search(event) {

      this.isSearching = true;

      // 店舗必須チェック
      if (event) {
        const validationResult = await event;
        if(!(validationResult.valid)) return;
      }

      api.post("/zaiko/search", {
          shopCode: this.searchCondition.shopCode ?? "",        // 店舗CD
          janCode: this.searchCondition.janCode,                    // JANコード
          zoneCode: this.searchCondition.zoneCode ?? "",              // ロケ
          productCode: this.searchCondition.typeCode,                  // 品番CD
          colorCode: this.searchCondition.colorCode,                // カラーCD
          sizeCode: this.searchCondition.sizeCode,                  // サイズCD
          brandCode: this.searchCondition.brandCode,  //ブランドCD
        }
      )
      .then((response)=>{
        this.dataList = response.data.results?.zaikoList ?? [];
        
        // 0件ダイアログ処理
        if(this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
      .catch((error) => {
        // 0件 dialog
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })


      this.resetCondition();
    },

    downloadCsv() {
      utils.simpleDownloadCsv(this.headers, this.sortedTable, "zaikoJanList.label.table.", 'zaikoJanList.fileNamePrefix.csv', this.$t);
    },
    initFromDashboard() {
      this.searchCondition.shopCode = store.getDashboardSiteCode();
      this.search();
    },
    isNavigatedFromDashboardPanel() {
      // NOTE
      // "遷移元がダッシュボード"という条件だけだと、メニューのリンクを押下しても遷移イベントが発生する。
      // パネル押下時にセッションに店舗コードを設定するので、それの有無でパネルからの遷移を判断する。
      return this.prevRoutePath == Paths.DASHBOARD && store.getDashboardSiteCode();
    },
  },
  created() {
    this.getValidShopSearchList()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoutePath = from?.path;
    })
  },
  mounted() {
    this.displayPerPage = utils.getDisplayPerPage(this.$t)
    this.displayPerPageItem = utils.getDisplayPerPageItem(this.$t);
    if (this.isNavigatedFromDashboardPanel()) {
      this.initFromDashboard();
      return;
    }
  },
};
</script>

<style>

</style>
