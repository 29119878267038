const store = {
  //ストレージにない場合はnull
  getToken: (): string|null => {
    return sessionStorage.getItem("authToken");
  },
  setToken: (authToken: string) => {
    sessionStorage.setItem("authToken", "Bearer " + authToken);
  },
  removeToken: (): void => {
    sessionStorage.removeItem("authToken");
  },
  isLoggedIn: (): boolean => {
    if(sessionStorage.getItem("authToken") != null) return true;
    return false;
  },
  getUserName: (): string|null => {
    return sessionStorage.getItem("userName");
  },
  setUserName: (userName: string) => {
    sessionStorage.setItem("userName", userName);
  },
  removeUserName: (): void => {
    sessionStorage.removeItem("userName");
  },

  // 棚卸関連
  setTanaoroshi: (tanaoroshiObj: object): void => {
    sessionStorage.setItem("tanaoroshi", JSON.stringify(tanaoroshiObj));
  },
  getTanaoroshi: (): object => {
    return JSON.parse(sessionStorage.getItem("tanaoroshi") ?? "");
  },
  removeTanaoroshi: (): void => {
    sessionStorage.removeItem("tanaoroshi");
  },
  setTanaZoneCode: (zoneCode: string): void => {
    sessionStorage.setItem("tanaZoneCode", zoneCode);
  },
  getTanaZoneCode: (): string => {
    return sessionStorage.getItem("tanaZoneCode") ?? "";
  },
  removeTanaZoneCode: (): void => {
    sessionStorage.removeItem("tanaZoneCode");
  },
  setTanaShopCode: (shopCode: string): void => {
    sessionStorage.setItem("tanaShopCode", shopCode);
  },
  getTanaShopCode: (): string => {
    return sessionStorage.getItem("tanaShopCode") ?? "";
  },
  removeTanaShopCode: (): void => {
    sessionStorage.removeItem("tanaShopCode");
  },
  hasTanaoroshiParam: (): boolean => {
    if(sessionStorage.getItem("tanaZoneCode") == null || sessionStorage.getItem("tanaoroshi") == null || sessionStorage.getItem("tanaShopCode") == null) return false;
    return true;
  },
  setUseInventoryResultCooperationBatch: (useInventoryResultCooperationBatch: string): void => {
    sessionStorage.setItem("useInventoryResultCooperationBatch", useInventoryResultCooperationBatch);
  },
  getUseInventoryResultCooperationBatch: (): object => {
    return JSON.parse(sessionStorage.getItem("useInventoryResultCooperationBatch") ?? "");
  },
  removeUseInventoryResultCooperationBatch: (): void => {
    sessionStorage.removeItem("useInventoryResultCooperationBatch");
  },

  // 在庫EPCと在庫EPCイベント関連
  // JAN、店舗コード、EPC
  setZaikoJanCode: (janCode: string): void => {
    sessionStorage.setItem("zaikoJanCode", janCode);
  },
  getZaikoJanCode: (): string => {
    return sessionStorage.getItem("zaikoJanCode") ?? "";
  },
  removeZaikoJanCode: (): void => {
    sessionStorage.removeItem("zaikoJanCode");
  },
  setZaikoShopCode: (shopCode: string): void => {
    sessionStorage.setItem("zaikoShopCode", shopCode);
  },
  getZaikoShopCode: (): string => {
    return sessionStorage.getItem("zaikoShopCode") ?? "";
  },
  removeZaikoShopCode: (): void => {
    sessionStorage.removeItem("zaikoShopCode");
  },
  setZaikoEpc: (epc: string): void => {
    sessionStorage.setItem("zaikoEpc", epc);
  },
  getZaikoEpc: (): string => {
    return sessionStorage.getItem("zaikoEpc") ?? "";
  },
  removeZaikoEpc: (): void => {
    sessionStorage.removeItem("zaikoEpc");
  },
  hasZaikoEpcParam: (): boolean => {
    if(sessionStorage.getItem("zaikoJanCode") == null || sessionStorage.getItem("zaikoShopCode") == null) return false;
    return true;
  },
  hasZaikoEpcEventParam: (): boolean => {
    if(sessionStorage.getItem("zaikoShopCode") == null || sessionStorage.getItem("zaikoEpc") == null) return false;
    return true;
  },

  // 入荷関連
  // 検索条件
  setNyuukaSearchCondition: (cond: string): void => {
    sessionStorage.setItem("NyuukaSearchCondition", cond);
  },
  getNyuukaSearchCondition: (): string => {
    return sessionStorage.getItem("NyuukaSearchCondition") ?? "";
  },
  removeNyuukaSearchCondition: (): void => {
    sessionStorage.removeItem("NyuukaSearchCondition");
  },
  // 実績予備項目
  setNyuukaResultSpareItemMap: (jsonMap: string): void => {
    sessionStorage.setItem("NyuukaResultSpareItemMap",  jsonMap);
  },
  getNyuukaResultSpareItemMap: (): string | null => {
    return sessionStorage.getItem("NyuukaResultSpareItemMap") ?? null;
  },
  removeNyuukaResultSpareItemMap: (): void => {
    sessionStorage.removeItem("NyuukaResultSpareItemMap");
  },
  // 入荷明細
  setNyukaDenpyoNumber: (denpyoNumber: string): void => {
    sessionStorage.setItem("NyukaDenpyoNumber", denpyoNumber);
  },
  getNyukaDenpyoNumber: (): string => {
    return sessionStorage.getItem("NyukaDenpyoNumber") ?? "";
  },
  removeNyukaDenpyoNumber: (): void => {
    sessionStorage.removeItem("NyukaDenpyoNumber");
  },
  hasNyukaDetailEventParam: (): boolean => {
    if(sessionStorage.getItem("NyukaDenpyoNumber") == null) return false;
    return true;
  },


  // 出荷関連
  // 検索条件
  setShukkaSearchCondition: (cond: string): void => {
    sessionStorage.setItem("ShukkaSearchCondition",  cond);
  },
  getShukkaSearchCondition: (): string => {
    return sessionStorage.getItem("ShukkaSearchCondition") ?? "";
  },
  removeShukkaSearchCondition: (): void => {
    sessionStorage.removeItem("ShukkaSearchCondition");
  },
  // 実績予備項目
  setShukkaResultSpareItemMap: (jsonMap: string): void => {
    sessionStorage.setItem("ShukkaResultSpareItemMap",  jsonMap);
  },
  getShukkaResultSpareItemMap: (): string | null => {
    return sessionStorage.getItem("ShukkaResultSpareItemMap") ?? null;
  },
  removeShukkaResultSpareItemMap: (): void => {
    sessionStorage.removeItem("ShukkaResultSpareItemMap");
  },
  // 出荷明細
  setShukkaDenpyoNumber: (denpyoNumber: string): void => {
    sessionStorage.setItem("ShukkaDenpyoNumber", denpyoNumber);
  },
  getShukkaDenpyoNumber: (): string => {
    return sessionStorage.getItem("ShukkaDenpyoNumber") ?? "";
  },
  removeShukkaDenpyoNumber: (): void => {
    sessionStorage.removeItem("ShukkaDenpyoNumber");
  },
  hasShukkaDetailEventParam: (): boolean => {
    if(sessionStorage.getItem("ShukkaDenpyoNumber") == null) return false;
    return true;
  },
  setShukkaManagementId: (managementId: string): void => {
    sessionStorage.setItem("ShukkaManagementId", managementId);
  },
  getShukkaManagementId: (): string => {
    return sessionStorage.getItem("ShukkaManagementId") ?? "";
  },
  removeShukkaManagementId: (): void => {
    sessionStorage.removeItem("ShukkaManagementId");
  },
  setShukkaLineNumber: (lineNumber: string): void => {
    sessionStorage.setItem("ShukkaLineNumber", lineNumber);
  },
  getShukkaLineNumber: (): string => {
    return sessionStorage.getItem("ShukkaLineNumber") ?? "";
  },
  removeShukkaLineNumber: (): void => {
    sessionStorage.removeItem("ShukkaLineNumber");
  },
  // メニュー関連
  setAvailableMenuList: (availableMenuList: string): void => {
    sessionStorage.setItem("availableMenuList", availableMenuList);
  },
  getAvailableMenuList: (): Array<string> | null => {
    const menuList = sessionStorage.getItem("availableMenuList");
    if (!menuList) return null;
    return menuList.split(",");
  },
  removeAvailableMenuList: (): void => {
    sessionStorage.removeItem("availableMenuList");
  },

  // ダッシュボード
  setDashboardSiteCode: (siteCode: string): void => {
    sessionStorage.setItem("DashboardSiteCode", siteCode);
  },
  getDashboardSiteCode: (): string => {
    return sessionStorage.getItem("DashboardSiteCode") ?? "";
  },
  removeDashboardSiteCode: (): void => {
    sessionStorage.removeItem("DashboardSiteCode");
  },
  setDashboardNyuukaKbn: (kbn: string): void => {
    sessionStorage.setItem("DashboardNyuukaKbn", kbn);
  },
  getDashboardNyuukaKbn: (): string => {
    return sessionStorage.getItem("DashboardNyuukaKbn") ?? "";
  },
  removeDashboardNyuukaKbn: (): void => {
    sessionStorage.removeItem("DashboardNyuukaKbn");
  },
  setDashboardShukkaKbn: (kbn: string): void => {
    sessionStorage.setItem("DashboardShukkaKbn", kbn);
  },
  getDashboardShukkaKbn: (): string => {
    return sessionStorage.getItem("DashboardShukkaKbn") ?? "";
  },
  removeDashboardShukkaKbn: (): void => {
    sessionStorage.removeItem("DashboardShukkaKbn");
  },
  setDashboardSearchDate: (kbn: string): void => {
    sessionStorage.setItem("DashboardSearchDate", kbn);
  },
  getDashboardSearchDate: (): string => {
    return sessionStorage.getItem("DashboardSearchDate") ?? "";
  },
  removeDashboardSearchDate: (): void => {
    sessionStorage.removeItem("DashboardSearchDate");
  },

  // 入荷区分リスト
  setReceiveKbnList: (kbnList: string): void => {
    sessionStorage.setItem("ReceiveKbnList", kbnList);
  },
  getReceiveKbnList: (): string => {
    return sessionStorage.getItem("ReceiveKbnList") ?? "";
  },
  removeReceiveKbnList: (): void => {
    sessionStorage.removeItem("ReceiveKbnList");
  },
  // 出荷区分リスト
  setShippedKbnList: (kbnList: string): void => {
    sessionStorage.setItem("ShippedKbnList", kbnList);
  },
  getShippedKbnList: (): string => {
    return sessionStorage.getItem("ShippedKbnList") ?? "";
  },
  removeShippedKbnList: (): void => {
    sessionStorage.removeItem("ShippedKbnList");
  },

  // 在庫移動実績
  // 検索条件
  setZaikoMovingSearchCondition: (cond: string): void => {
    sessionStorage.setItem("ZaikoMovingSearchCondition", cond);
  },
  getZaikoMovingSearchCondition: (): string => {
    return sessionStorage.getItem("ZaikoMovingSearchCondition") ?? "";
  },
  removeZaikoMovingSearchCondition: (): void => {
    sessionStorage.removeItem("ZaikoMovingSearchCondition");
  },

  setZaikoMovingResultSelectedItem: (item: string): void => {
    sessionStorage.setItem("ZaikoMovingResultSelectedItem", item);
  },
  getZaikoMovingResultSelectedItem: (): string => {
    return sessionStorage.getItem("ZaikoMovingResultSelectedItem") ?? "";
  },
  removeZaikoMovingResultSelectedItem: (): void => {
    sessionStorage.removeItem("ZaikoMovingResultSelectedItem");
  },

  initializeSession: (): void => {
    store.removeToken();
    store.removeUserName();
    store.removeTanaZoneCode();
    store.removeTanaShopCode();
    store.removeTanaoroshi();
    store.removeZaikoJanCode();
    store.removeZaikoShopCode();
    store.removeZaikoEpc();
    store.removeAvailableMenuList();
    store.removeNyuukaSearchCondition();
    store.removeNyukaDenpyoNumber();
    store.removeShukkaSearchCondition();
    store.removeShukkaResultSpareItemMap();
    store.removeShukkaDenpyoNumber();
    store.removeShukkaManagementId();
    store.removeShukkaLineNumber();
    store.removeDashboardSiteCode();
    store.removeDashboardNyuukaKbn();
    store.removeDashboardShukkaKbn();
    store.removeDashboardSearchDate();
    store.removeReceiveKbnList();
    store.removeShippedKbnList();
    store.removeZaikoMovingSearchCondition();
    store.removeZaikoMovingResultSelectedItem();
    store.removeNyuukaResultSpareItemMap();
    store.removeUseInventoryResultCooperationBatch();
  }

};

export default store;
