<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
         <v-form @submit.prevent="search" ref="formRefs">
          <v-row>
            <v-col cols="12">
              <v-alert
                v-model="alertFlg"
                type="success"
                density="compact"
                :title=successMsg
                :text="$t('tanaoroshi.message.success')"
                closable
                class="ma-2"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="searchCondition.shopCode"
                    :items="shopItem"
                    item-title="code_name"
                    item-value="code"
                    :label="$t('tanaoroshi.label.shopCode')"
                  />
            </v-col>
            <v-col cols="9" class="text-right">
                  <v-btn
                    theme="dark"
                   class="ml-4 px-8 bg-grey-darken-3"
                   elevation="2"
                   type="submit"
                   >{{ $t('common.action.search') }}</v-btn
                  >
            </v-col>
          </v-row>
          <v-row v-if="canDownloadCsvMonthly">
            <v-col cols="12" class="text-right">
              <v-btn dark class="bg-indigo-darken-1" elevation="2" @click="showDialog4Register">{{ $t('tanaoroshi.action.downloadCsvMonthly') }}</v-btn>
            </v-col>
          </v-row>
         </v-form> 
        </v-container>
        <div class="table_wrap">
          <table>
            <thead>
              <tr>
                <th v-for="header in headers" :key="header" @click.stop="tableSort(header)" :style="cursorCheck(header)">
                  {{ $t("tanaoroshi.label.table." + header) }}</th>
              </tr>
            </thead>
            <tbody v-for="(tana) in sortedTable" :key="tana.num">
              <tr v-for="(zone, idx) in tana.zoneList" :key="zone.zoneCode">
                <template v-if="idx === 0">
                  <td>{{ tana.num }}</td>
                  <td>{{ tana.startDatetime }}</td>
                  <td>{{ tana.endDatetime }}</td>
                  <td>{{ tana.statusLabel }}</td>
                  <td>{{ tana.inventoryModeLabel }}</td>
                </template>
                <template v-else>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </template>
                <td>
                  <a style= "color: #1976d2;" @click="moveTanaoroshiSai(tana, zone.zoneCode)" :style="cursorCheck(null)">
                    {{ zone.zoneName }}
                  </a>
                </td>
                <td>{{ zone.planSuuryo }}</td>
                <td>{{ zone.readSuuryo }}</td>
                <td>{{ zone.diffSuuryo }}</td>
                <td>{{ zone.readPrecision }}</td>
                <td>{{ idx === 0 ? tana.kakuteisyaCode : "" }}</td>
                <td width='4em'>
                  <template
                    v-if="
                      idx == 0 &&
                      filterActionItems(tana).length > 0
                    "
                  >
                    <v-select
                      :items="filterActionItems(tana)"
                      label=""
                      v-model="tana.actionForm"
                      item-title="label"
                      item-value="id"
                      @update:model-value="changeActionButton(tana, tana.tanaoroshiId, $event)"
                      density="compact"
                      variant="outlined"
                      solo
                      hide-details
                    />
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div> 
        <TanaoroshiConfirmDialog
          v-if="dialogVisible"
          :dialog-data="confirmDialog"
          :handle-ok="confirmOk"
          :handle-cancel="confirmCancel"
        />
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog"/>
        <TanaoroshiResultCsvDownloadDialog v-if="registerDialogVisible" :dialog-data="registerDialog" :handle-ok="confirmRegisterOk"
          :handle-cancel="confirmRegisterCancel" />
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import TanaoroshiConfirmDialog from "@/components/dialog/TanaoroshiConfirmDialog";
import AppWrapperView from "@/components/views/AppWrapperView";
import api from "@/apis/staff";
import store from "@/store";
import utils from "@/utils";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import ZeroData from "@/components/dialog/ZeroDataDialog";
import TanaoroshiResultCsvDownloadDialog from "@/components/dialog/TanaoroshiResultCsvDownloadDialog";

// statusID定義
const statusId = {
  notEnforcement: 1, // 未実施
  enforcing: 2, // 実施中
  saved: 3, // 保存済
  confirmed: 4, // 確定済
  relaying: 5, // 連携中
  relayed: 6, // 連携済み
}

export default {
  components: { AppWrapperView, TanaoroshiConfirmDialog, ZeroData, TanaoroshiResultCsvDownloadDialog},
  computed: {
    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.tanaoroshiList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      // 変換
      tempArray = tempArray.map((x, index) => {
        // noはなし
        // x.no = index + 1;
        
        //確定されていない場合は確定者コードを空文字にする
        if(x.statusId < statusId.confirmed) x.kakuteisyaCode = "";
        
        x.startDatetime = utils.putSplitWordToCalendarWithTime(x.startDatetime);
        x.endDatetime = utils.putSplitWordToCalendarWithTime(x.endDatetime);
        x.showActionButton = utils.StringToBool(x.showActionButton);
        x.isStartedKakutei = utils.StringToBool(x.isStartedKakutei);
        return x;
      })

      return tempArray;
    },
    // ヘッダー
    headers() {
      return this.$getTableDef("TanaoroshiPage");
    },
  },

  data() {
    return {
    // アクション選択肢定義
      actionButtonKakutei: { id: 2, label: this.$t('tanaoroshi.action.actionButtonKakutei') },
      actionButtonRenkei: { id: 3, label: this.$t('tanaoroshi.action.actionButtonRenkei') },
      actionButtonDownloadCsv: { id: 4, label: this.$t('tanaoroshi.action.actionButtonDownloadCsv') },
      actionButtonClear: {id: 0, label: this.$t('tanaoroshi.action.actionButtonClear')},
      shopItem: [],
      searchCondition: {
        shopCode: null,         // 店舗コード
      },

      // 現在表示している棚卸の店舗情報
      currentShopCode: null,

      dialogVisible: false,
      confirmDialog: {
        actionType: 0,
        tanaoroshi: {},
      },

      tanaoroshiList: [],
      
      sortState: {target: "", ascent: true},

      alertFlg: false,
      successMsg: "",

      currentConfirmId: null,

      // 0件取得時の警告ダイアログ
      zeroDataWarningDialogFlg: false,
      // 検索中
      isSearching: false,

      // タイムアウトのID
      timeoutId: null,
      // 登録ダイアログ表示フラグ
      registerDialogVisible: false,
      // ユーザ登録ダイアログに渡すデータ
      registerDialog: {
      },
      canDownloadCsvMonthly: false,
    };
  },
  methods: {
    //カーソルの変更
    cursorCheck(value) {
      if(!(value == "num" || value == "startDatetime" || value == "endDatetime" || value == "statusLabel" || value == null)) return "cursor: not-allowed;";
      else return "cursor: pointer;"
    },

    moveTanaoroshiSai(tanaoroshi, zoneCode) {
      // 理論在庫:APV在庫の全体以外のゾーンコードの場合は棚卸差異画面へ移行しない。
      // NOTE この値を使って条件分岐をするという意味不明なことをしているので注意
      const	nameerp = this.$t('tanaoroshi.label.nameerp');
      if(!(tanaoroshi.inventoryModeLabel == nameerp && zoneCode != 0)){
        store.setTanaoroshi(tanaoroshi);
        store.setTanaZoneCode(zoneCode);
        store.setTanaShopCode(this.currentShopCode)
        router.push({name: Paths.TANAOROSHI_SAI})
      }
    },
    //ソートリセット
    resetCondition(){
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.confirmDialog = {
        actionType: 0,
        tanaoroshi: {},
        shopCode: null,
      };
    },
    async confirmOk(actionType) {
      await this.reload();
      this.closeDialog();
      this.clearActionFormValue();
      
      this.alertFlg = true;
      // 5秒後にアラートを消す
      this.timeoutId = setTimeout(() => {
        this.alertFlg = false;
        this.timeoutId = null;
      }, 5000);
      if(actionType == this.actionButtonKakutei) this.successMsg = this.$t('tanaoroshi.message.successKakutei');
      if(actionType == this.actionButtonRenkei) this.successMsg = this.$t('tanaoroshi.message.successRenkei');
      if(actionType == this.actionButtonDownloadCsv) this.successMsg = this.actionButtonDownloadCsv.label;
      else this.successMsg = "";
      
    },
    confirmCancel() {
      this.closeDialog();
      this.clearActionFormValue();
    },
    showDialog(tanaoroshi, actionType) {
      this.alertFlg = false;
      this.dialogVisible = true;
      this.confirmDialog.actionType = actionType;
      this.confirmDialog.tanaoroshi = tanaoroshi;
      this.confirmDialog.shopCode = this.currentShopCode;
    },
    tableSort(headValue) {

      if(!(headValue == "num" || headValue == "startDatetime" || headValue == "endDatetime" || headValue == "statusLabel")) return;

      else{
        if(this.sortState.target == headValue){
          this.sortState.ascent = !this.sortState.ascent;
        }
        else{
          this.sortState.target = headValue;
          this.sortState.ascent = true;
        }
      }

    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    changeActionButton(tanaoroshi, tanaoroshiId, actionType) {
      // 選択項目をリセット
      this.currentConfirmId = tanaoroshiId;

      this.showDialog(tanaoroshi, actionType);
    },

    clearActionFormValue() {
      this.tanaoroshiList.find(x => x.tanaoroshiId == this.currentConfirmId).actionForm = null;
    },

    // 確定、連携の表記制御
    // showActionButtonとisStartedKakuteiで見る
    filterActionItems(tanaRow) {
      
      if (tanaRow.statusId == statusId.saved) {
        if (store.getUseInventoryResultCooperationBatch() == "1") {
          // 連携バッチを使用する場合は、確定+連携のみ選択可能
          return [this.actionButtonRenkei];
        } else {
          // 連携バッチを使用しない場合は、確定のみ選択可能
          return [this.actionButtonKakutei];
        }
      } else if (tanaRow.canDownloadCsv) {
        return [this.actionButtonDownloadCsv];
      } else {
        // 表示しない
        return [];
      }
    },

    async reload() {
      this.isSearching = true;
      await api.post("/tanaoroshi/search", {
        shopCode: this.searchCondition.shopCode ?? ""
      })
        .then((response) => {
          this.tanaoroshiList = response.data.results?.tanaoroshiList ?? [];
          this.resetCondition();

        // 0件ダイアログ処理
        if(this.tanaoroshiList.length == 0) this.zeroDataWarningDialogFlg = true;
        })
        .catch((error) => {
          // 0件 dialog
          this.tanaoroshiList = [];
          this.zeroDataWarningDialogFlg = true;
        });
        this.currentShopCode = this.searchCondition.shopCode;
        this.isSearching = false;
    },

    //検索ボタンイベント
    async search(event) {
    // 店舗必須チェック
    const validationResult = await event;
      if(!(validationResult.valid)) return;

        api.post("/tanaoroshi/search", {
          shopCode: this.searchCondition.shopCode ?? ""
        })
        .then((response) => {
            this.tanaoroshiList = response.data.results?.tanaoroshiList ?? [];
            this.resetCondition();

        // 0件ダイアログ処理
        if(this.tanaoroshiList.length == 0) this.zeroDataWarningDialogFlg = true;
        })
        .catch((error) => {
          // 0件 dialog
          this.tanaoroshiList = [];
          this.zeroDataWarningDialogFlg = true;
        });
        this.currentShopCode = this.searchCondition.shopCode;
        this.isSearching = false;
    },

    // 店舗リストの取得
    // 自店舗検索リスト取得
    // selectSiteFlag：0：他店舗含む / 1：自店舗のみ
    getValidShopSearchList(){
      api.post("/store/search", {selectSiteFlag: 1})
      .then((response)=>{
        if(response.status == 1) return; //do nothing
        response.data.results?.shopList.forEach(element => {
          this.shopItem.push({name: element.name, code: element.code, code_name: element.code+'_'+element.name}) 
        })
        // 先頭値を入れる
        if(this.shopItem.length > 0) this.searchCondition.shopCode = this.shopItem[0].code;
      })
      .catch((error) => {
        //do nothing
      })
    },

    // 登録ダイアログ表示
    showDialog4Register() {
      this.registerDialogVisible = true;
      this.registerDialog.shopItem = this.shopItem;
    },
    // 登録/更新ダイアログ非表示
    closeRegisterDialog() {
      this.registerDialogVisible = false;
    },
    // 登録/更新ダイアログキャンセル
    confirmRegisterCancel() {
      this.closeRegisterDialog();
    },
    // 登録/更新ダイアログOK押下
    confirmRegisterOk() {
      this.closeRegisterDialog();
    },
    fetchCanDownloadCsvMonthly(settingId){
      api.post("/config/setting", {settingId: "CAN_DOWNLOAD_TANAOROSHI_CSV_MONTHLLY"})
      .then((response)=>{
        if(response.data.status == 1) return; //do nothing
        this.canDownloadCsvMonthly = "1" == response.data.results.settingValue;
      })
      .catch((error) => {
        //do nothing
      })
    },
  },

  unmounted() {
    if(this.timeoutId != null) {
      clearTimeout(this.timeoutId);
      this.alertFlg = false;
    }
  },

  mounted() {
    this.getValidShopSearchList()
    this.fetchCanDownloadCsvMonthly();
  },

};
</script>

<style lang="scss" scoped>
.table_wrap {
  overflow: auto;
  margin: 30px;
  table {
    white-space: nowrap;
    border: solid 1px #bdbdbd;
    width: 100%;
    min-width: 900px;
    border-radius: 10px;
    border-collapse: collapse;
    text-align: center;
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
    }
    tbody {
      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
    }
    th {
      padding: 10px;
      background-color: #3949ab;
      color: #fff;
    }
  }
  td {
    padding: 6px 3px;
  }
}
</style>
