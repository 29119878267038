import axios, { AxiosResponse } from "axios";
import store from "@/store/index";

const api = {

  apiConfig: ():object => {
    const config = {
      headers:{
        "Content-Type": "application/json",
        "x-api-key": process.env.VUE_APP_API_KEY,
        "Access-Control-Allow-Origin": "*",
      },
    };

    // 何らかのトークンがあれば追加して返す
    // TODO 認証回りが直ったら環境変数から取らないようにする
    if(/*process.env?.VUE_APP_AUTH_KEY ??*/ store.getToken()) config.headers = Object.assign(config.headers, {"Authorization": /*process.env.VUE_APP_AUTH_KEY ??*/ store.getToken()});
    return config;
  },
  // ファイル送信用設定
  apiConfig4PostFile: ():object => {
    const config = {
      headers:{
        "Content-Type": "multipart/form-data",
        "x-api-key": process.env.VUE_APP_API_KEY,
        "Access-Control-Allow-Origin": "*",
      },
    };
    if(store.getToken()) config.headers = Object.assign(config.headers, {"Authorization": store.getToken()});
    return config;
  },

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
  ): Promise<R> {
    return axios.post(process.env.VUE_APP_API_BASE_URL+url, data, this.apiConfig());
  },
  get<T = any, R = AxiosResponse<T>>(
    url: string,
  ): Promise<R> {
    return axios.get(process.env.VUE_APP_API_BASE_URL+url, this.apiConfig());
  },
  // ファイル送信
  postFile<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
  ): Promise<R> {
    return axios.post(process.env.VUE_APP_API_BASE_URL+url, data, this.apiConfig4PostFile());
  },

};
export default api;
